import React from 'react'
import Header from './Header'
import Headers from './Header2'
import defaultImage from '../../assets/image/192.png'

import '../../App.css'

function IndexHeader() {
    return (
        <div >

        <Header />
            <Headers />
        </div>
    )
}

export default IndexHeader
